import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Know Where You Fit In: high-skill, middle-skill, and line workers",
  "author": "Purepost",
  "date": "2018-04-04T05:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "know-where-you-fit-in-high-skill-middle-skill-and-low-skill-workers",
  "draft": false,
  "meta_title": "Know Where You Fit In: high-skill, middle-skill, and line workers"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Do you know where you fit in, in the labor force?`}</p>
    <p>{`The labor force is no longer divided into the white-collar and blue-collar workers. It's actually broken up into three categories:`}</p>
    <p><strong parentName="p">{`High-Skilled:`}</strong>{` a college degree or higher`}</p>
    <p><strong parentName="p">{`Middle-Skilled:`}</strong>{` associates degree or some college courses`}</p>
    <p><strong parentName="p">{`Low-Line:`}</strong>{` high school diploma or GED`}</p>
    <p>{`When you look closely at U.S. jobs and workers, we're actually over capacity with high-skilled and low-skilled workers. In other words, there are not enough jobs to fill the demand for workers in the two categories. However, the opposite holds true for middle-skilled workers. As a nation, we don't have enough workers to fill the demand for middle-skilled jobs.`}</p>
    <p>{`This is where the military is poised perfectly to answer the call. When you look at the skills breakdown, of the military, based on education alone - we stand strong at 65% middle-skilled. This does not include all the skills and experience we obtain over the years of our service. Some examples of middle-skilled work are:`}</p>
    <ul>
      <li parentName="ul">{`Health care workers/technicians`}</li>
      <li parentName="ul">{`Legal assistants`}</li>
      <li parentName="ul">{`Machinists`}</li>
      <li parentName="ul">{`Electricians`}</li>
      <li parentName="ul">{`Plumbers`}</li>
      <li parentName="ul">{`Clerical workers`}</li>
      <li parentName="ul">{`Engineering technicians`}</li>
      <li parentName="ul">{`Green technology jobs`}</li>
      <li parentName="ul">{`Sales`}</li>
      <li parentName="ul">{`Transportation`}</li>
      <li parentName="ul">{`Construction and repair`}</li>
      <li parentName="ul">{`Production`}</li>
    </ul>
    <p>{`As you can tell, most of this work can be found in the military or is work that is transferable based off the skills, aptitude, and experience we acquire while serving.`}</p>
    <p>{`This data does not mean it's going to be difficult or impossible to find a high-skilled or low-skilled job. Nor does it prove that it will be simple to find middle-skilled work. What this does show is where one fits in by education, and can be used to set expectations or prepare for a proactive transition into the private sector.`}</p>
    <p>{`For further information, see `}<a parentName="p" {...{
        "href": "http://www.achieve.org/files/MiddleSkillsJobs.pdf"
      }}>{`The Future of the U.S. Workforce`}</a>{`. If you're interested in how the workforce breaks down in your home state or the state you wish to call home, check out `}<a parentName="p" {...{
        "href": "http://www.nationalskillscoalition.org/state-policy/fact-sheets"
      }}>{`The National Skills Coalition`}</a>{` by state.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      